/* eslint-disable no-underscore-dangle */
import RBTCWrapperProxyAbi from '@/abis/RBTCWrapperProxy.json';
import SovrynSwapNetworkAbi from '@/abis/SovrynSwapNetwork.json';
import ERC20TokenAbi from '@/abis/ERC20Token.json';
import { ethers } from 'ethers';
import Vue from 'vue';
import { DEFAULT_ALLOWANCE } from '@/utils/commons.consts';
import { SwapHistory } from '@/services/swaps';
import { addresses } from './constants';
import { signer } from './utils';

const gasLimit = 750000;
export default class SovrynSwap {
  constructor(chainId) {
    this.sovrynSwapNetworkAddress = addresses[chainId].sovrynSwapNetwork;
    this.sovrynSwapNetwork = new ethers.Contract(this.sovrynSwapNetworkAddress,
      SovrynSwapNetworkAbi, Vue.web3);
    this.wsSovrynSwapNetwork = new ethers.Contract(this.sovrynSwapNetworkAddress,
      SovrynSwapNetworkAbi, Vue.web3Ws);

    this.rbtcWrapperProxyAddress = addresses[chainId].rbtcWrapperProxy;
    this.rbtcWrapperProxy = new ethers.Contract(this.rbtcWrapperProxyAddress,
      RBTCWrapperProxyAbi, Vue.web3);
    this.wsRbtcWrapperProxy = new ethers.Contract(this.rbtcWrapperProxyAddress,
      RBTCWrapperProxyAbi, Vue.web3Ws);

    this.SwapHistory = SwapHistory;
  }

  async getConversionPath(sourceTokenAddress, destTokenAddress) {
    const conversionPath = await this.sovrynSwapNetwork
      .callStatic.conversionPath(sourceTokenAddress, destTokenAddress);
    return conversionPath;
  }

  // eslint-disable-next-line consistent-return
  async getExpextedSwapAmount(conversionPath, amount) {
    try {
      const targetAmount = await this.sovrynSwapNetwork
        .callStatic.rateByPath(conversionPath, ethers.utils.parseEther(amount.toString()));
      return targetAmount;
    } catch (e) {
      console.error('error', e);
    }
  }

  // Swaps RBTC to any other token
  async convertFromRBTC(account, conversionPath, amount, minReturn, gasPrice) {
    const accountSigner = signer(account);
    return this.rbtcWrapperProxy.connect(accountSigner)
      .convertByPath(
        conversionPath,
        ethers.utils.parseEther(amount.toString()),
        ethers.utils.parseEther(minReturn.toString()),
        {
          value: ethers.utils.parseEther(amount.toString()),
          gasLimit,
          gasPrice,
        },
      );
  }

  // Swaps any token to RBTC
  async convertToRBTC(account, conversionPath, amount, minReturn, tokenAddress, gasPrice) {
    const accountSigner = signer(account);
    const ERC20Token = new ethers.Contract(conversionPath[0],
      ERC20TokenAbi, Vue.web3);

    const decimals = await ERC20Token.callStatic.decimals();
    const walletAddress = await account.getAddress();
    const requiredAllowance = ethers.utils.parseUnits(amount.toString(), decimals);
    const currentAllowance = await ERC20Token.connect(accountSigner)
      .allowance(walletAddress, tokenAddress);
    if (currentAllowance.lte(requiredAllowance)) {
      const tx = await ERC20Token.connect(accountSigner)
        .approve(
          this.rbtcWrapperProxy.address,
          // FIXME: ethers.utils.parseEther(amount.toString()),
          DEFAULT_ALLOWANCE,
        );
      await Vue.web3.waitForTransaction(tx.hash);
    }

    return this.rbtcWrapperProxy.connect(accountSigner)
      .convertByPath(conversionPath, ethers.utils.parseEther(amount.toString()),
        ethers.utils.parseEther(minReturn.toString()), { gasLimit, gasPrice });
  }

  // Swaps any token to any other token except for RBTC, instead it does the swap to wRBTC
  // NOTE: in case of using convertByPath to swap to RBTC is necesary to withdraw from
  // wRBTC token to get the RBTCs
  async convertByPath(
    account, beneficiary, conversionPath, amount, minReturn, sourceToken, gasPrice,
  ) {
    const accountSigner = signer(account);
    const ERC20Token = new ethers.Contract(conversionPath[0],
      ERC20TokenAbi, Vue.web3);

    const decimals = await ERC20Token.callStatic.decimals();
    const walletAddress = await account.getAddress();
    const requiredAllowance = ethers.utils.parseUnits(amount.toString(), decimals);
    const currentAllowance = await ERC20Token.connect(accountSigner)
      .allowance(walletAddress, sourceToken);

    if (currentAllowance.lte(requiredAllowance)) {
      const tx = await ERC20Token.connect(accountSigner)
        .approve(
          this.sovrynSwapNetwork.address,
          // FIXME: ethers.utils.parseEther(amount.toString()),
          DEFAULT_ALLOWANCE,
        );
      await Vue.web3.waitForTransaction(tx.hash);
    }

    return this.sovrynSwapNetwork.connect(accountSigner)
      .convertByPath(conversionPath, ethers.utils.parseEther(amount.toString()),
        ethers.utils.parseEther(minReturn.toString()), beneficiary,
        '0x0000000000000000000000000000000000000000', 0, { gasLimit, gasPrice });
  }

  async getSwapHistory(account, filter) {
    try {
      const response = await this.SwapHistory(account.toLowerCase(), filter);
      const formattedData = response.data.getUserSwaps.map((swap) => ({
        events: {
          event_name: 'Exchange',
          event_timestamp: swap.timestamp,
          tx_hash: swap.transaction.id.toLowerCase(),
        },
        fromToken: swap.fromToken.id.toLowerCase(),
        fromAmount: Number(swap.fromAmount),
        toToken: swap.toToken.id.toLowerCase(),
        toAmount: Number(swap.toAmount),
      }));
      return formattedData;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
}
