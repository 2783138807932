import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';
import { addresses } from '@/middleware/contracts/constants';
import * as constants from '@/store/constants';
import store from '../store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
const chainId = Number(process.env.VUE_APP_DEFAULT_CHAIN_ID);

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  const metaDescription = document.querySelector('meta[name="description"]');
  if (to.meta.description && metaDescription) {
    metaDescription.setAttribute('content', to.meta.description);
  } else if (to.meta.description) {
    const description = document.createElement('meta');
    description.setAttribute('name', 'description');
    description.setAttribute('content', to.meta.description);
    document.head.appendChild(description);
  }
  if (to.name === constants.ROUTES.MARKETS.CHART && to.params.id) {
    const marketId = to.params.id;
    const marketTitle = `Tasas para el mercado ${marketId}`;
    const marketDescription = `Conoce las tasas actuales en Tropykus de depósitos y préstamos en ${marketId}.`;

    document.title = marketTitle;
    if (metaDescription) {
      metaDescription.setAttribute('content', marketDescription);
    } else {
      const description = document.createElement('meta');
      description.setAttribute('name', 'description');
      description.setAttribute('content', marketDescription);
      document.head.appendChild(description);
    }
  }

  if (to.params?.id === addresses[chainId].kXUSD && (to.name === 'Deposit' || to.name === 'Borrow')) {
    next({ path: '/home' });
  }
  if (to.name === constants.ROUTE_NAMES.BALANCE) {
    if (to.matched.some((record) => record.meta.requiredWallet)) {
      if (store.getters.SESSION_IS_CONNECTED) {
        store.dispatch(constants.SESSION_SHOW_DIALOG_CONNECT, false);
        store.dispatch(constants.SESSION_ROUTE_BALANCE, false);
        next();
      } else {
        store.dispatch(constants.SESSION_SHOW_DIALOG_CONNECT, true);
        store.dispatch(constants.SESSION_ROUTE_BALANCE, true);
      }
    }
  } else {
    store.dispatch(constants.SESSION_ROUTE_BALANCE, false);
    next();
  }

  if (
    to.name === constants.ROUTES.DEPOSIT.DEPOSIT
    || to.name === constants.ROUTES.DEPOSIT.WITHDRAW
  ) {
    if (to.matched.some((record) => record.meta.requiredWallet)) {
      if (store.getters.SESSION_IS_CONNECTED) {
        store.dispatch(constants.SESSION_SHOW_DIALOG_CONNECT, false);
        store.dispatch(constants.SESSION_ROUTE_DEPOSITS, false);
        next();
      } else {
        store.dispatch(constants.SESSION_SHOW_DIALOG_CONNECT, true);
        store.dispatch(constants.SESSION_ROUTE_DEPOSITS, true);
      }
    }
  } else {
    store.dispatch(constants.SESSION_ROUTE_DEPOSITS, false);
    next();
  }

  if (
    to.name === constants.ROUTES.BORROW.BORROW
    || to.name === constants.ROUTES.BORROW.REPAY
  ) {
    if (to.matched.some((record) => record.meta.requiredWallet)) {
      if (store.getters.SESSION_IS_CONNECTED) {
        store.dispatch(constants.SESSION_SHOW_DIALOG_CONNECT, false);
        store.dispatch(constants.SESSION_ROUTE_BORROWS, false);
        next();
      } else {
        store.dispatch(constants.SESSION_SHOW_DIALOG_CONNECT, true);
        store.dispatch(constants.SESSION_ROUTE_BORROWS, true);
      }
    }
  } else {
    store.dispatch(constants.SESSION_ROUTE_BORROWS, false);
    next();
  }
  if (
    to.name === constants.ROUTES.HOME.QR
    || to.name === constants.ROUTES.HOME.SEND
  ) {
    if (to.matched.some((record) => record.meta.requiredWallet)) {
      if (store.getters.SESSION_IS_CONNECTED) {
        store.dispatch(constants.SESSION_SHOW_DIALOG_CONNECT, false);
        store.dispatch(constants.SESSION_ROUTE_BORROWS, false);
        next();
      } else {
        store.dispatch(constants.SESSION_SHOW_DIALOG_CONNECT, true);
        store.dispatch(constants.SESSION_ROUTE_BORROWS, true);
      }
    }
  } else {
    store.dispatch(constants.SESSION_ROUTE_BORROWS, false);
    next();
  }
  if (from.name === null && to.name === constants.ROUTE_NAMES.BALANCE) {
    next({ path: '/' });
  }
});
router.afterEach((to) => {
  if (to.meta.requiresCanonical) {
    const canonicalLink = document.querySelector("link[rel='canonical']");
    if (canonicalLink) {
      canonicalLink.setAttribute('href', window.location.href);
    } else {
      const link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', window.location.href);
      document.head.appendChild(link);
    }
  }
});

export default router;
