export const addresses = {
  31: {
    deployBlock: 2230000, // Smart contract version 0.2.6
    priceOracleProxy: '0x9fbb872d3b45f95b4e3126bc767553d3fa1e31c0',
    kRIF: '0x23b60e2193057b4b2823973b7478489a076de84f',
    kDOC: '0x71e6b108d823c2786f8ef63a3e0589576b4f3914',
    kXUSD: '0xb33fa8ebe2ea3019e73db2df0e9968330e9c8869',
    kUSDT: '0x5539d6d48a2147cb824c5c45baaa01e7e1a2694f',
    kRBTC: '0x5b35072cd6110606c8421e013304110fa04a32a3',
    kSAT: '0x13f3a4013e77a65b0cd941b8b0e1687e8f3a0e1d',
    kBRZ: '0xfd222218f840a23a1ef4cdd251b3a3bbd7c62bd4',
    kBPRO: '0x844a99ba756539aee698ce2915d678ba0fee4d9d',
    kUSDRIF: '0xfbee4444493194468df1de7450a37d840eb8b555',
    tropykusLens: '0x5b2cd255d82de649bb871fdeb711898c32eb900f',
    comptroller: '0xB1beC5376929b4E0235f1353819DBA92c4B0C6Bb',
    cRBTCCompanion: '0x4c64b488282b63e3e7b0835c8ac0af1d5a2da995',
    sovrynSwapNetwork: '0x6390df6de9f24902b29740371525c2ceaa8f5a4f',
    rbtcWrapperProxy: '0x2c468f9c82c20c37cd1606cf3a09702f94910691',
    wRBTC: '0x69fe5cec81d5ef92600c1a0db1f11986ab3758ab',
    sovrynDOC: '0xCb46C0DdC60d18eFEB0e586c17AF6Ea36452DaE0',
    sovrynUSDT: '0x4d5a316d23ebe168d8f887b4447bf8dbfa4901cc',
    sovrynXUSD: '0xA9262cC3fb54Ea55b1b0AF00EFcA9416B8D59570',
    sovrynRIF: '0x19f64674d8a5b4e652319f5e239efd3bc969a1fe',
    sovrynBPRO: '0x4da7997a819bb46b6758b9102234c289dd2ad3bf',
    fastRbtcToBtcNetwork: '0x10c848e9495a32aca95f6c23c92eca2b2be9903a',
  },
  // 30:RSK Mainnet
  30: {
    deployBlock: 4000000, // Smart contract version 0.2.6
    priceOracleProxy: '0x7fa5500c978e89660bf3bd0526f8f7164de0b38f',
    kRIF: '0x3134b7fbfca5db217eca523eab1941452cf35163',
    kDOC: '0x544eb90e766b405134b3b3f62b6b4c23fcd5fda2',
    kXUSD: '0xe17551201eeaefbd625ca4fb48d49c06e7ac064b',
    kUSDT: '0xedaefc6b596ed38d712100976969975a37c84464',
    kRBTC: '0x0aeadb9d4c6a80462a47e87e76e487fa8b9a37d7',
    kSAT: '0xd2ec53e8dd00d204d3d9313af5474eb9f5188ef6',
    kBPRO: '0x405062731d8656af5950ef952be9fa110878036b',
    kUSDRIF: '0xddf3ce45fcf080df61ee61dac5ddefef7ed4f46c',
    tropykusLens: '0xfc5e99a649a5a44fc45d4f1dcca292f9b69f5b65',
    comptroller: '0x962308fEf8edFaDD705384840e7701F8f39eD0c0',
    cRBTCCompanion: '0xfa7bf65eba46f0533426ba39890356f996edf58a',
    sovrynSwapNetwork: '0x98ace08d2b759a265ae326f010496bcd63c15afc',
    rbtcWrapperProxy: '0xa917bf723433d020a15629eba71f6c2a6b38e52d',
    wRBTC: '0x542fda317318ebf1d3deaf76e0b632741a7e677d',
    sovrynDOC: '0xE700691Da7B9851F2F35f8b8182C69C53ccad9DB',
    sovrynUSDT: '0xef213441a85df4d7acbdae0cf78004e1e486bb96',
    sovrynXUSD: '0xb5999795BE0eBb5BAb23144Aa5fD6a02d080299f',
    sovrynRIF: '0x2acc95758f8b5f583470ba265eb685a8f45fc9d5',
    sovrynUSDRIF: '0x3A15461d8AE0f0Fb5fA2629e9dA7D66A794a6E37',
    sovrynBPRO: '0x440cd83c160de5c96ddb20246815ea44c7abbca8',
    fastRbtcToBtcNetwork: '0x1A8E78B41bc5Ab9Ebb6996136622B9b41A601b5C',
  },
};

export const SMTH = true;
