/* eslint-disable no-multi-assign */
import { ethers } from 'ethers';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/storage';
import 'firebase/firestore';
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import * as chart from 'chart.js';
import Tropykus from '@tropykus-finance/tropykus';
import apolloProvider from '@/utils/graphql';
import { firebaseConfig } from '../firebaseConfig';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import axios from './plugins/axios';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './i18n';
/* import VueMeta from 'vue-meta'; */
import './styles/main.scss';
import '@/config/pwa.config';

require('./filters');

sync(store, router);

const provider = Number(process.env.VUE_APP_DEFAULT_CHAIN_ID) === 30
  ? process.env.VUE_APP_RSK_NODE : process.env.VUE_APP_RSK_NODE_TESTNET;
const web3 = new ethers.providers.JsonRpcProvider(provider);
const format = web3.formatter.formats;
format.receipt.root = format.receipt.logsBloom;
Object.assign(web3.formatter, { format });
Vue.prototype.$web3 = Vue.web3 = web3;

const wsprovider = Number(process.env.VUE_APP_DEFAULT_CHAIN_ID) === 30
  ? process.env.VUE_APP_WS_PROVIDER : process.env.VUE_APP_WS_PROVIDER_TESTNET;
const web3Ws = new ethers.providers.WebSocketProvider(wsprovider);

const formatWs = web3Ws.formatter.formats;
formatWs.receipt.root = formatWs.receipt.logsBloom;
Object.assign(web3Ws.formatter, { format: formatWs });

Vue.prototype.$web3Ws = Vue.web3Ws = web3Ws;
Vue.prototype.$tropykus = Vue.tropykus = new Tropykus(web3, web3Ws, 900000);

Vue.prototype.$firebase = Vue.firebase = firebase.initializeApp(firebaseConfig);
Vue.prototype.$db = Vue.db = firebase.firestore();

firebase.analytics();

new Vue({
  axios,
  router,
  store,
  vuetify,
  chart,
  i18n,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app');
