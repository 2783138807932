/* eslint-disable no-underscore-dangle */
import FastBtcAbi from '@/abis/FastBTCBridge.json';
import Vue from 'vue';
import { ethers, FixedNumber, utils } from 'ethers';
import { signer } from './utils';
import { addresses } from './constants';

export default class FastBtc {
  constructor(chainId) {
    this.fastRbtcToBtcNetwork = addresses[chainId].fastRbtcToBtcNetwork;
    this.instance = new ethers.Contract(this.fastRbtcToBtcNetwork, FastBtcAbi.abi, Vue.web3);
  }

  convertRBTCToBTC(account, btcAddress, amount) {
    const value = FixedNumber.from(amount.toString(), 'fixed80x18');
    const accountSigner = signer(account);
    return this.instance.connect(accountSigner)
      .transferToBtc(btcAddress, { value: utils.parseEther(value._value), gasLimit: 200000 });
  }
}
