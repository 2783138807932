import * as constants from '@/store/constants';
import { addresses } from '@/middleware/contracts/constants';
import { getUserActivity } from '@/services/users';
import {
  SovrynSwap,
} from '@/middleware';

const state = {
  userActivity: [],
  isLoadingAllActivity: false,
  isLoadingPartialActivity: false,
  dataToFilter: false,
  swapActivity: [],
  isLoadingSwap: false,
  menuOption: 'activity',
};
const actions = {

  [constants.BALANCE_GET_USER_ACTIVITY]: async ({ commit, rootState }, info) => {
    const { Session: { userId }, Balance: { userActivity } } = rootState;
    const { allActivity, take, skip } = info;
    commit(constants.BALANCE_SET_PROPERTY, allActivity
      ? { isLoadingAllActivity: true }
      : { isLoadingPartialActivity: true });
    const { activity } = await getUserActivity(userId, take, skip);
    userActivity.push(...activity);
    userActivity.sort((a, b) => new Date(b.events.event_timestamp)
      - new Date(a.events.event_timestamp));
    commit(constants.BALANCE_SET_PROPERTY,
      { dataToFilter: activity.length >= 10 });
    commit(constants.BALANCE_SET_PROPERTY, { userActivity });
    commit(constants.BALANCE_SET_PROPERTY, allActivity
      ? { isLoadingAllActivity: false }
      : { isLoadingPartialActivity: false });
  },

  [constants.BALANCE_GET_SWAP_ACTIVITY]: async ({ commit, rootState }) => {
    const { Session: { chainId, walletAddress }, Market: { markets } } = rootState;
    const sovrynSwap = new SovrynSwap(chainId);
    const mappedAddresses = new Map([
      [addresses[chainId].wRBTC, addresses[chainId].kRBTC],
      [addresses[chainId].sovrynDOC.toLowerCase(), addresses[chainId].kDOC],
      [addresses[chainId].sovrynXUSD.toLowerCase(), addresses[chainId].kXUSD],
      [addresses[chainId].sovrynBPRO.toLowerCase(), addresses[chainId].kBPRO],
      [addresses[chainId].sovrynRIF.toLowerCase(), addresses[chainId].kRIF],
    ]);

    commit(constants.BALANCE_SET_PROPERTY, { isLoadingSwap: true });
    const swapHistory = (await sovrynSwap.getSwapHistory(walletAddress, 'success'))
      .map((mkt) => {
        const storedToMarket = markets
          .filter((m) => m.id === mappedAddresses.get(mkt.toToken)).pop();
        const storedFromMarket = markets
          .filter((m) => m.id === mappedAddresses.get(mkt.fromToken)).pop();
        if (storedFromMarket === undefined || storedToMarket === undefined) {
          return {
            events: { event_name: '' },
          };
        }
        const toSymbol = storedToMarket.underlyingSymbol;
        const fromSymbol = storedFromMarket.underlyingSymbol;
        const toSymbolImg = storedToMarket.symbolImg;
        const fromSymbolImg = storedFromMarket.symbolImg;
        return {
          ...mkt,
          symbol_image: mkt.symbolImg,
          toToken: mappedAddresses.get(mkt.toToken),
          fromToken: mappedAddresses.get(mkt.fromToken),
          toTokenSymbol: toSymbol,
          fromTokenSymbol: fromSymbol,
          toTokenSymbolImg: toSymbolImg,
          fromTokenSymbolImg: fromSymbolImg,
        };
      });
    commit(constants.BALANCE_SET_PROPERTY, { isLoadingSwap: false });
    commit(constants.BALANCE_SET_PROPERTY, { swapActivity: swapHistory });
  },

};
const mutations = {
  // eslint-disable-next-line no-shadow
  [constants.BALANCE_SET_PROPERTY]: (state, data) => {
    const [[property, value]] = Object.entries(data);
    state[property] = value;
  },
};

export default {
  state,
  actions,
  mutations,
};
