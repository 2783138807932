import { ROUTES } from '@/store/constants';
import i18n from '@/i18n';

export default [
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "Building" */ '@/views/NotFound.vue'),
  },
  {
    path: '',
    redirect: { name: ROUTES.HOME.SECTION, params: { id: 'savings' } },
  },
  {
    path: '/auth',
    name: 'Web3Auth-Authentication',
    component: () => import(/* webpackChunkName: "Building" */ '@/views/Auth/Web3AuthLogin.view.vue'),
  },

  {
    path: '/home',
    /* meta: {
      title: i18n.t('routes.home.title'),
      description: i18n.t('routes.home.description'),
    }, */
    component: () => import(/* webpackChunkName: "Home" */'@/views/Home/Home.vue'),
    children: [
      {
        path: '',
        name: ROUTES.HOME.DASHBOARD,
        meta: {
          requiresCanonical: true,
          title: i18n.t('routes.home.title'),
          description: i18n.t('routes.home.description'),
        },
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Home/Dashboard.vue'),
      },
      {
        path: 'section/:id',
        meta: {
          requiredWallet: true,
          requiresCanonical: true,
          title: i18n.t('routes.deposit-borrow.title'),
          description: i18n.t('routes.deposit-borrow.description')
        },
        name: ROUTES.HOME.SECTION,
        component: () => import(/* webpackChunkName: "Home" */'@/views/Home/Section.vue'),
      },
      {
        path: 'qr',
        meta: { requiredWallet: true },
        name: ROUTES.HOME.QR,
        component: () => import(/* webpackChunkName: "QR" */'@/views/Home/Qr.vue'),
      },
      {
        path: 'send',
        meta: { requiredWallet: true },
        name: ROUTES.HOME.SEND,
        component: () => import(/* webpackChunkName: "QR" */'@/views/Home/Send.vue'),
      },
      {
        path: 'sendWallet',
        meta: { requiredWallet: true },
        name: ROUTES.HOME.SENDWALLET,
        component: () => import(/* webpackChunkName: "QR" */'@/views/Home/SendWallet.vue'),
      },
    ],
  },
  {
    path: '/deposits',
    component: () => import(/* webpackChunkName: "Deposits" */ '@/views/Deposits/Deposits.vue'),
    children: [

      {
        path: ':id',
        meta: { requiredWallet: true },
        component: () => import(/* webpackChunkName: "Deposits" */ '@/views/Deposits/Market.vue'),
        children: [
          {
            path: '/',
            name: ROUTES.DEPOSIT.DEPOSIT,
            component: () => import(/* webpackChunkName: "Deposits" */ '@/views/Deposits/MarketDeposit.vue'),
          },
          {
            path: 'withdraw',
            name: ROUTES.DEPOSIT.WITHDRAW,
            component: () => import(/* webpackChunkName: "Deposits" */ '@/views/Deposits/MarketWithdraw.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/borrows',
    component: () => import(/* webpackChunkName: "Borrows" */ '@/views/Borrows/Borrows.vue'),
    children: [
      {
        path: ':id',
        meta: { requiredWallet: true },
        component: () => import(/* webpackChunkName: "Borrows" */ '@/views/Borrows/Market.vue'),
        children: [
          {
            path: '/',
            name: ROUTES.BORROW.BORROW,
            component: () => import(/* webpackChunkName: "Borrows" */ '@/views/Borrows/MarketBorrow.vue'),
          },
          {
            path: 'repay',
            name: ROUTES.BORROW.REPAY,
            component: () => import(/* webpackChunkName: "Borrows" */ '@/views/Borrows/MarketRepay.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/balance',
    name: 'Balance',
    meta: { requiredWallet: true },
    component: () => import(/* webpackChunkName: "Balance" */ '@/views/Balance.vue'),
  },
  {
    path: '/buy-crypto',
    meta: { requiredWallet: false },
    component: () => import(/* webpackChunkName: "BuyCrypto" */ '@/views/BuyCrypto/Directory.vue'),
    children: [
      {
        path: '',
        name: ROUTES.BUY_CRYPTO.DIRECTORY,
        component: () => import(/* webpackChunkName: "BuyCrypto" */ '@/views/BuyCrypto/Directory.vue'),
      },
    ],
  },
  {
    path: '/markets',
    name: ROUTES.MARKETS.ROOT,
    meta: {
      requiresCanonical: true,
      title: i18n.t('routes.markets.title'),
      description: i18n.t('routes.markets.description')
    },
    component: () => import(/* webpackChunkName: "Markets" */ '@/views/Markets/Markets.vue'),
  },
  {
    path: '/markets/:id',
    name: ROUTES.MARKETS.CHART,
    meta: {
      requiresCanonical: true,
    },
    component: () => import(/* webpackChunkName: "Chart" */ '@/views/Markets/Chart.vue'),
  },
  {
    path: '/trade-swaps',
    name: 'Exchanges',
    meta: {
      requiresCanonical: true,
      title: i18n.t('routes.swaps.title'),
      description: i18n.t('routes.swaps.description')
    },
    component: () => import(/* webpackChunkName: "TradeSwaps" */ '@/views/TradeSwaps.vue'),
  },
  {
    path: '/blocked',
    name: 'Blocked',
    component: () => import(/* webpackChunkName: "AccessBlocked" */ '@/views/AccessBlocked.vue'),
  },
];
