<template>
  <span  class="copy-clipboard" :class=" !fromHomeQr? 'background-light': ''">
    <v-icon v-if=" !fromHomeQr" color="primary"  @click="copyToClipboard()" small >
      $vuetify.icons.clipboard </v-icon>
    <v-icon v-else color="white" small @click="copyToClipboard()">
       $vuetify.icons.clipboard </v-icon>
    <div
      v-if="showMessage"
      @click="showMessage = false"
      class="copied-confirmation-container pa-1 mt-2"
    >
      <span class="white--text pa-1">
        {{ confirmationText }}
      </span>
    </div>
  </span>
</template>

<script>
import copy from 'copy-to-clipboard';

export default {
  name: 'CopyClipboard',
  props: {
    valueToCopy: {
      type: String,
      required: true,
    },
    confirmationText: {
      type: String,
      required: true,
    },
    fromHomeQr: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      showMessage: false,
    };
  },
  methods: {
    copyToClipboard() {
      copy(this.valueToCopy);
      this.showMessage = true;
      setTimeout(
        async () => {
          this.showMessage = false;
        },
        4000,
      );
    },
  },
};
</script>
