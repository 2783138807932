import { render, staticRenderFns } from "./Change.vue?vue&type=template&id=47dc1f56&scoped=true&"
var script = {}
import style0 from "./Change.vue?vue&type=style&index=0&id=47dc1f56&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47dc1f56",
  null
  
)

export default component.exports