<template>
  <v-dialog v-model="dialog" width="414" overlay-opacity="0.6" overlay-color="#000"
 persistent >
    <v-card class="modal-connect-wallet py-2 pb-5" >
      <v-row class="mb-4 ma-0 d-flex justify-center mt-8">
        <h1 class="h1-heading text-primary"> {{ $t('dialog.wallet.title1') }}</h1>
      </v-row>
      <v-row class="mb-2 ma-0 d-flex justify-center mt-6">
        <img v-if="criptoWallets" class="mb-5 arrow-back" src="@/assets/icons/goBack.svg"
          @click="criptoWallets = !criptoWallets"
        alt="go_back">
        <h2 class="h2-heading text-primary">{{ $t('dialog.wallet.title2') }}</h2>
      </v-row>
     <v-icon class="btn-close-dialog " @click="closeDialog">mdi-close</v-icon>
      <div v-if="!criptoWallets" class="d-flex justify-center mb-3">
        <v-btn text to="/auth"
          class="btn-wallet">
            <v-icon size="20" class="mt-1">mdi-email-outline</v-icon>
          <span class="b2-secondary text-info ml-2">Email</span>
        </v-btn>
      </div>
      <div v-if="!criptoWallets && !isMobile" class="d-flex justify-center mb-3 is-wallet-connect">
        <v-btn text @click="criptoWallets = !criptoWallets"
          class="btn-wallet d-flex justify-start">
          <img class="ml-9 mr-3" src="@/assets/icons/group.svg"
            alt="Wallet connect icon" />
          <span class="b2-secondary text-info">Billeteras cripto</span>
        </v-btn>
      </div>
      <template v-if="criptoWallets || isMobile">
        <div class="d-flex justify-center mb-3 is-wallet-connect">
        <v-btn text @click="connectRlogin"
          class="btn-wallet d-flex justify-start">
          <img class="ml-7 mr-9" src="@/assets/wallets/wallet-connect.svg"
            alt="Wallet connect icon" />
          <span class="b2-secondary text-info">{{ $t('dialog.wallet.button2') }}</span>
        </v-btn>
      </div>
      <div v-if="!isMobile || isMetaMask" class="d-flex justify-center mb-3">
        <v-btn text @click="connectWeb3Wallet(constants.WALLET_METAMASK)"
          class="btn-wallet d-flex justify-start">
          <img class="ml-7 mr-9" src="@/assets/wallets/metamask.svg" alt="MetaMask icon"/>
            <div class="b2-secondary text-info">Metamask</div>
        </v-btn>
      </div>
        <div v-if="!isMobile" class="d-flex justify-center mb-3">
        </div>
       <div v-if="!isMobile" class="d-flex justify-center mb-3">
          <v-btn text @click="connectRlogin"
            class="btn-wallet d-flex justify-start">
            <img class="ml-7 mr-9" src="@/assets/wallets/ledger.svg" alt="Ledger icon" />
            <span class="b2-secondary text-info">Ledger</span>
          </v-btn>
        </div>
        <div v-if="!isMobile" class="d-flex justify-center mb-3">
          <v-btn text @click="connectRlogin"
            class="btn-wallet d-flex justify-start">
            <img class="ml-7 mr-9" src="@/assets/wallets/trezor.svg" alt="Ledger icon" />
            <span class="b2-secondary text-info">Trezor</span>
          </v-btn>
        </div>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import * as constants from '@/store/constants';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ConnectWallet',
  data() {
    return {
      constants,
      dialog: this.showModal,
      isMobile: false,
      isMetaMask: false,
      isBrave: false,
      criptoWallets: false,
    };
  },
  props: {
    showModal: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    ...mapActions({
      connectToWeb3: constants.SESSION_CONNECT_WEB3,
      connectRlogin: constants.SESSION_CONNECT_RLOGIN,
      setMobile: constants.SESSION_IS_MOBILE,
    }),
    connectWeb3Wallet(walletType) {
      if (walletType === constants.WALLET_CONNECT) {
        this.$emit('closed', true);
      } else {
        this.$emit('closed', false);
        this.connectToWeb3({ wallet: walletType });
      }
    },
    redirect(routePath, section) {
      const to = { name: routePath, params: { id: section } };
      this.$router.push(to);
    },
    closeDialog() {
      this.$emit('closed', false);
      if (this.$route.name === constants.ROUTES.HOME.SECTION) return;
      this.$router.push({ name: constants.ROUTES.HOME.SECTION, params: { id: 'savings' } });
    },

    detectMetamask() {
      const { ethereum } = window;
      if (ethereum && ethereum.isMetaMask) this.isMetaMask = true;
      else this.isMetaMask = false;
    },
  },
  computed: {
    ...mapState({
      web3AuthInstance: (state) => state.Session.web3AuthInstance,
    }),
    modalHeight() {
      if (!this.isMobile) return '100%';
      if (this.isMobile && this.isMetaMask) return 150;
      return 230;
    },
  },
  async mounted() {
    this.isBrave = !!navigator?.brave;
    if ('ontouchstart' in document.documentElement) this.isMobile = true;
    else this.isMobile = false;
    this.setMobile(this.isMobile);

    if (window.ethereum) this.detectMetamask();
    else {
      window.addEventListener('ethereum#initialized', this.detectMetamask, {
        once: true,
      });
      // If the event is not dispatched by the end of the timeout,
      // the user probably doesn't have MetaMask installed.
      setTimeout(this.detectMetamask, 300); // 3 seconds
    }
  },
};
</script>
