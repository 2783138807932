<template>
  <div>
    <v-dialog v-model="dialog" persistent content-class="d-terms-conditions"
      overlay-opacity="0.6" overlay-color="#000">
      <v-card
        class="dialog-terms-conditions ma-0 secondary-color d-flex align-center justify-center">
        <v-icon class="btn-close-dialog" @click="closeDialog(true)">mdi-close</v-icon>
        <div class="content-terms text-caption mt-10 mb-11">
          <div class="h3-sections-heading text-info">
            {{$t('dialog.terms.description1')}}
          </div>
          <div class="box-terms d-flex justify-center align-center mt-8">
            <img src="@/assets/dialog/info.svg" class="mr-3" alt="info-icon"/>
            {{$t('dialog.terms.description2')}}
          </div>
          <div class="box-policy">
            <div class="d-flex align-center justify-start mt-6">
              <v-checkbox
                v-model="terms"
                color="#042F24"
                class=""
                hide-details
              ></v-checkbox>
              <div class="mt-5">{{$t('dialog.terms.description6')}}
                <a target="_blank" class="text-info"
                  :href="getUrlConditions">
                  {{$t('dialog.terms.description7')}}
                </a>
              </div>
            </div>
            <div class="d-flex align-center justify-start mb-2">
              <v-checkbox
                v-model="policy"
                color="#042F24"
                class="check-terms"
                hide-details
              ></v-checkbox>
              <div class="">{{$t('dialog.terms.description8')}}
                <a target="_blank" class="!text-info"
                  :href="getUrlPolicy">
                  {{$t('dialog.terms.description9')}}
                </a>
              </div>
            </div>
          </div>

          <v-btn text block class="btn mt-10"
            :disabled="!activeButton"
            @click="closeDialog(false)"
            :class="activeButton ? 'primary-color' : 'btn-disabled'"
          >
            <span class="white--text">
              {{$t('dialog.terms.btn')}}
            </span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {
  name: 'TermsAndConditions',
  data() {
    return {
      dialog: true,
      terms: false,
      policy: false,
      restriction: false,
      infoDialog: {},
    };
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    showModal() {
      this.dialog = this.showModal;
    },
  },
  computed: {
    activeButton() {
      return this.terms === true && this.policy === true;
    },
    getUrlPolicy() {
      return this.$t('dialog.terms.policy');
    },
    getUrlConditions() {
      return this.$t('dialog.terms.conditions');
    },
  },
  methods: {
    closeDialog(value) {
      if (value) {
        this.$emit('closed', null);
      } else if (this.activeButton) {
        localStorage.setItem('tropykus-terms-conditions', this.activeButton);
        this.$emit('closed', this.activeButton);
      } else {
        this.$emit('closed', null);
      }
    },
  },
};
</script>
