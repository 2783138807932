import { graphqlClient } from '@/utils/graphql';
import swapsQuery from '@/graphql/query/Swaps.gql';

// eslint-disable-next-line import/prefer-default-export
export async function SwapHistory(walletAddress, filter) {
  const swapHistory = await graphqlClient.subscribe({
    query: swapsQuery.getSwaps,
    variables: {
      address: walletAddress,
      filter: {
        status: filter,
      }
    },
  });

  return new Promise((resolve, reject) => {
    swapHistory.subscribe({
      next(response) {
        const { data } = response;
        resolve({ data });
      },
      error(error) {
        console.error(error);
        reject(error);
      },
    });
  });
}
