/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import { FixedNumber, utils, ethers } from 'ethers';
import * as constants from '@/store/constants';
import { findOneMarket } from '@/services/markets';
import StandardTokenAbi from '@/abis/StandardToken.json';
import { addresses } from './constants';

export const signer = (_signer) => {
  const format = Vue.web3.formatter.formats;
  Object.assign(_signer.provider.formatter, { format });
  return _signer;
};

export const getFeePrice = async (gasUsed) => {
  const { market } = await findOneMarket();
  const rbtcPrice = market.underlying_token_price;

  const gasPriceEhters = FixedNumber.from('0.00000000006', 'fixed80x18');
  const price = FixedNumber.from(rbtcPrice.toString(), 'fixed80x18');

  const gasUsedTx = FixedNumber.from(gasUsed.toString(), 'fixed80x18');
  const feePrice = gasUsedTx.mulUnsafe(gasPriceEhters).mulUnsafe(price);
  const gasPrice = Number(feePrice._value);
  return gasPrice;
};

export const tokenAddressesMap = (chainId) => new Map([
  [addresses[chainId].kRBTC, addresses[chainId].wRBTC],
  [addresses[chainId].kDOC, addresses[chainId].sovrynDOC.toLowerCase()],
  [addresses[chainId].kBPRO, addresses[chainId].sovrynBPRO.toLowerCase()],
  [addresses[chainId].kUSDRIF, addresses[chainId].sovrynUSDRIF.toLowerCase()],
  [addresses[chainId].kRIF, addresses[chainId].sovrynRIF.toLowerCase()]
]);

export const reduceDecimals = (value = 0, token = '') => {
  const amount = value || 0;
  const aux = amount.toString().split('.');
  let decimalPart = '';
  const normalizedToken = token.toLowerCase();
  if (value <= 0) return 0;

  if (normalizedToken === constants.RBTC_SYMBOL.toLowerCase()
  || normalizedToken === constants.TRBTC_SYMBOL.toLowerCase()
    || normalizedToken === constants.RBITCOIN_SYMBOL.toLowerCase()
    || normalizedToken === constants.BPRO_SYMBOL.toLowerCase()
    || normalizedToken === constants.RIF_SYMBOL.toLowerCase()
    || normalizedToken === constants.USDRIF_SYMBOL.toLowerCase()) {
    if (value < 0.000001) return value;
    decimalPart = aux[1] ? '.'.concat(aux[1].substring(0, 6)) : '';
    return Number(`${aux[0]}${decimalPart}`);
  }
  if (value < 0.0000001) return value;
  decimalPart = aux[1] ? '.'.concat(aux[1].substring(0, 2)) : '';
  return Number(`${aux[0]}${decimalPart}`);
};

// eslint-disable-next-line consistent-return
export const transferTokens = async (account, chainId, address, sendToken, amount) => {
  const parsedAmount = utils.parseEther(amount.toString());

  const txData = {
    to: address.toLowerCase(),
    value: parsedAmount,
  };
  let tx;
  const accountSigner = signer(account);
  const mappedAddresses = tokenAddressesMap(chainId);
  try {
    if (sendToken !== 'RBTC') {
      const sovrynAddress = mappedAddresses.get(addresses[chainId][`k${sendToken}`]);
      const token = new ethers.Contract(
        sovrynAddress,
        StandardTokenAbi,
        Vue.web3,
      );
      tx = await token.connect(accountSigner).transfer(txData.to, txData.value);
      return tx;
    }
    tx = await accountSigner.sendTransaction(txData);
    return tx;
  } catch (e) {
    console.error(e);
    return e;
  }
};
